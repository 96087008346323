<template lang="pug">
.container
  page-title
  .card
    .card-content
      app-form(@submit.prevent='getShipments' :is-saving='isLoading' button-text='Consultar')
        field(label='Fecha inicial' fied='startDate')
          app-date(id='startDate' v-model='params.startDate' type='date' required)
        field(label='Fecha final' fied='endDate')
          app-date(id='endDate' v-model='params.endDate' type='date' required)
      div(v-if='rows.length')
        b-button(@click='newShipment' type='is-primary' outlined) Agregar
        b-button.ml-2(@click='editShipment' type='is-primary' outlined v-if='selected && selected.id') Editar
        export-button.ml-2(:data='rows' :columns='exportColumns' name='visitas')
        b-button.ml-2(@click='getShipments' outlined :disabled='isLoading') Actualizar
      .text-center.mb-1(v-if='isLoading') Cargando...
      vue-editable-grid(
        class="shipments-grid"
        id="shipments"
        :column-defs='columnDefs'
        :row-data='rows'
        row-data-key='shipmentId'
        @row-selected='rowSelected'
        @link-clicked='linkClicked'
        v-else
      )
  b-modal(v-model='shipmentModalActive' has-modal-card trap-focus aria-role='dialog' aria-modal :can-cancel='["x"]')
    modal-card(v-if='selected' :title='`Envío`')
      app-form(@submit.prevent='submit' :is-saving='isSaving')
        field(label='Código' field='code')
          input#price.input(placeholder='Código' v-model.number='selected.code' required)
        field(label='Origen' field='origin')
          input#price.input(placeholder='Código' v-model.number='selected.origin')
        field(label='Destino' field='destination')
          input#price.input(placeholder='Destino' v-model.number='selected.destination')
        field(label='Estado' field='status')
          app-select(id='status' :enum='shipmentStatusEnum' :enum-names='shipmentStatusDescriptions' v-model.number='selected.status' required)
        field(label='Asignado a' field='trailerId')
          devices-picker(id='trailerId' v-model='selected.trailerId' only-ids)
  b-modal(v-model='trackModalActive' has-modal-card trap-focus aria-role='dialog' aria-modal :can-cancel='["x"]')
    modal-card(v-if='selected')
      div(v-if='deviceLoading') Consultando última ubicación...
      div(v-else)
        .mb-3(v-if='trailer')
          device-status.mr-2(:device='trailer')
          span.mr-2 Activo: {{ trailer.activeDate | datetime }}
          span Ubicación: {{ trailer.reportDate | datetime }}
        l-map(
          style="height: 400px; width: 500px"
          ref='mainMap'
          :zoom='zoom'
          :center='center'
          :options='options'
        )
          l-tile-layer(:url='url')
          l-control(position='topleft')
            map-controls
          l-marker(
            v-if='trailer'
            :key='trailer.id'
            :lat-lng='[trailer.lat, trailer.lng]'
          )
</template>

<script>
import { mapState } from 'vuex'
import { LMap, LTileLayer, LMarker, LControl } from 'vue2-leaflet'
import { inpointNewSchedule } from '@/router/routes'
import shipmentsService from '@/services/shipments.service'
import reportsService from '@/services/reports.service'
import DevicesPicker from '@/components/devices/DevicesPicker'
import MapControls from '@/components/shared/MapControls'
import DeviceStatus from '@/components/devices/DeviceStatus'

import { shipmentsColumnDefs } from '@/constants/grids'
import { shipmentStatusColors, shipmentStatusDescriptions, shipmentStatusEnum } from '@/constants/enums'
import devicesService from '@/services/devices.service'

const exportColumns = [
  { headerName: 'Fecha y hora', field: 'date' },
  { headerName: 'Ubicación', field: 'locationName' },
  { headerName: 'Usuario', field: 'userName' }
]

export default {
  components: { DevicesPicker, LMap, LTileLayer, LMarker, LControl, MapControls, DeviceStatus },
  data () {
    return {
      params: {
        ...reportsService.getStartEndDates()
      },
      rows: [],
      trailer: null,
      deviceLoading: false,
      shipmentModalActive: false,
      trackModalActive: false,
      selected: null,
      isLoading: false,
      isSaving: false,
      exportColumns,
      inpointNewSchedule,
      columnDefs: shipmentsColumnDefs,
      shipmentStatusEnum,
      shipmentStatusDescriptions
    }
  },
  created () {
    this.getShipments()
  },
  computed: {
    ...mapState({
      url: state => state.map.url,
      defaultZoom: state => state.map.zoom,
      options: state => state.map.options
    }),
    trailerHaveLocation () {
      return this.trailer && this.trailer.lat && this.trailer.lng
    },
    center () {
      if (this.trailerHaveLocation) {
        return [this.trailer.lat, this.trailer.lng]
      }
      return [6.1967846, -75.5749092]
    },
    zoom () {
      return this.trailerHaveLocation ? 16 : this.defaultZoom
    }
  },
  methods: {
    async getShipments () {
      this.isLoading = true
      const result = await shipmentsService.getAll(this.params)
      if (result) {
        this.rows = result.map(shipment => {
          shipment.statusDescription = shipmentStatusDescriptions[shipment.status]
          shipment.trailerName = shipment.trailer ? shipment.trailer.name : null
          shipment.reportDate = shipment.reportDate ? shipment.trailer.reportDate : null
          if (shipment.trailer) {
            shipment.trackAction = 'Rastrear'
          }
          shipment.$rowStyle = { color: shipmentStatusColors[shipment.status] }
          return shipment
        })
      }
      this.isLoading = false
    },
    newShipment () {
      this.selected = {
        status: 1
      }
      this.shipmentModalActive = true
    },
    editShipment () {
      this.shipmentModalActive = true
    },
    rowSelected (row) {
      if (row) {
        this.selected = { ...row.rowData }
      }
    },
    async linkClicked (row) {
      if (row) {
        this.trackModalActive = true
        this.deviceLoading = true
        const result = await devicesService.getOne(row.rowData.trailerId)
        if (result) {
          this.trailer = result
        }
        this.deviceLoading = false
      }
    },
    async submit () {
      const result = await shipmentsService.save(this.selected)
      if (result) {
        this.shipmentModalActive = false
        this.selected = null
        this.getShipments()
      }
    }
  }
}
</script>
